import React from 'react';
import PropTypes from "prop-types";
import config from "../../../config/config";
import GetTranslation from "../../../helper/GetTranslation";

const RendererImage = (props) => {
    return (
        <img
            src={config.apiHost + GetTranslation({
                translations: props.element?.translations,
                defaultContent: props.element.src,
                translationField: 'src'
            })?.contentUrl}
            alt={''}
            className={'image-responsive'}
        />
    );
};

RendererImage.propTypes = {
    element: PropTypes.object.isRequired
}

export default RendererImage;