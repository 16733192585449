import React from 'react';
import {AppBar, Grid, IconButton, Toolbar} from "@mui/material";

import Navigation from "../navigation/Navigation";
import defaultLogo from "../../assets/img/logo_bmxx.png";
import {Logout} from "@mui/icons-material";
import GlobalTrans from "../../helper/GlobalTrans";
import {useStore} from "../../store/useStore";

const Header = () => {
    const {state, dispatch} = useStore();

    const logout = () => {
        dispatch({
            type: 'deleteTokens'
        });
        dispatch({
            type: 'deletePin'
        });
        dispatch({
            type: 'resetRefresher'
        });
    };

    const LogoutButton = () => {
        if (!state.token) {
            return null;
        }

        return (
            <Grid item xs={4} sm={'auto'} textAlign={'center'}>
                <IconButton aria-label={GlobalTrans(('logout'))} title={GlobalTrans(('logout'))}
                            color={'inherit'} onClick={logout}>
                    <Logout/>
                </IconButton>
            </Grid>
        );
    }

    return (
        <AppBar className={'main-header'}>
            <Toolbar>
                <Grid container alignItems={'center'} className={'main-header--toolbar-grid'} spacing={3}>
                    <Grid item xs={12} sm>
                        <div className="main-header--logo">
                            <img src={defaultLogo} alt={'logo'} title={'logo'} className={'logo'}/>
                        </div>
                    </Grid>
                    <Grid item xs={(state.token) ? 8 : 12} sm={'auto'}>
                        <Navigation/>
                    </Grid>
                    <LogoutButton/>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default Header;