import React, {useEffect, useState} from 'react';
import {Box, Grid, Paper, Tab, Tabs} from "@mui/material";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import Login from "./Login";
import Register from "./Register";
import {useStore} from "../../store/useStore";
import {useIntl} from "react-intl";
import Token from "../../helper/Token";
import RefreshToken from "../../components/refreshToken/RefreshToken";
import axios from "axios";

const Authentication = () => {
    const {state, dispatch} = useStore();
    const intl = useIntl();

    const [cancelToken] = useState(axios.CancelToken.source());
    const [validatingJWT, setValidatingJWT] = React.useState(false);
    const [value, setValue] = React.useState((!state.pinHasOwner) ? 1 : 0);

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken]);

    useEffect(() => {
        if (state.pinHasOwner && value === 1) {
            setValue(0);
        }
    }, [state.pinHasOwner, value])

    useEffect(() => {
        if (!validatingJWT && !state.token && state.refreshToken) {
            setValidatingJWT(true);

            Token.refresh(
                (res) => {
                    dispatch({
                        type: 'setTokens',
                        payload: {
                            token: res.data.token,
                            refreshToken: res.data.refresh_token
                        }
                    });
                },
                () => {
                    dispatch({type: "deleteTokens"});
                    dispatch({type: "setTokenExpired", payload: true});
                    setValidatingJWT(false);
                },
                state.refreshToken,
                cancelToken.token
            )
        }
    }, [validatingJWT, cancelToken.token, dispatch, state.refreshToken, state.token]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const TabPanel = (props) => {
        const {children, value, index, ...other} = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{p: 3}}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    return (
        (
            (validatingJWT || (!state.token && state.refreshToken)) &&
            <RefreshToken/>
        )
        ||
        <Grid container justifyContent={'center'}>
            <Grid item xs={12} md={6}>
                <Paper className={'color-theme main-paper'}>
                    <h1>{GlobalTransIntl('login_headline', intl)}</h1>

                    <Box sx={{width: '100%'}}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <Tabs value={value} onChange={handleChange}>
                                <Tab label={GlobalTransIntl('login', intl)}/>
                                {
                                    !state.pinHasOwner &&
                                    <Tab label={GlobalTransIntl('register', intl)}/>
                                }
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <Login/>
                        </TabPanel>
                        {
                            !state.pinHasOwner &&
                            <TabPanel value={value} index={1}>
                                <Register/>
                            </TabPanel>
                        }
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Authentication;