import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from "prop-types";
import GetElement from "../../../helper/GetElement";

const RendererMailTasks = (props) => {
    const [mounted, setMounted] = useState(false);
    const [taskIndex, setTaskIndex] = useState(0);
    const [taskAmount, setTaskAmount] = useState(0);
    const [taskFinished, setTaskFinished] = useState(0);
    const [answered, setAnswered] = useState(false);
    const taskColAmount = props.element.colElements.length;

    const isTaskIndexAlreadyAnswered = useCallback((taskIndexValue) => {
        if (Object.keys(props.answerJson).length && props.answerJson[props.pageId]) {
            const currentTasks = props.element.colElements[taskIndexValue];
            const answers = props.answerJson[props.pageId].elements;

            return !!currentTasks.find(task => answers[task.id]);
        }

        return false;
    }, [props.answerJson, props.element.colElements, props.pageId]);

    const getTasksAmount = useCallback((taskIndexValue) => {
        const currentTasks = props.element.colElements[taskIndexValue];
        let counter = 0;

        currentTasks.forEach(taskElement => {
            if (taskElement.validationType) {
                counter++;
            }
        });

        return counter;
    }, [props.element.colElements]);

    const prepareTaskIndex = useCallback((taskIndexValue) => {
        if (taskIndexValue < props.element.colElements.length) {
            if (isTaskIndexAlreadyAnswered(taskIndexValue)) {
                prepareTaskIndex(taskIndexValue + 1);
            } else {
                setTaskIndex(taskIndexValue);
                setTaskAmount(getTasksAmount(taskIndexValue));
            }
        }
    }, [props.element.colElements, isTaskIndexAlreadyAnswered, getTasksAmount]);

    useEffect(() => {
        if (!mounted) {
            setMounted(true);
            props.setReadyForNextPage(false);
            prepareTaskIndex(taskIndex);
        }
    }, [mounted, prepareTaskIndex, taskIndex, props]);

    useEffect(() => {
        return () => {
            const setReadyForNextPage = props.setReadyForNextPage;
            setReadyForNextPage(true);
        }
    }, [props.setReadyForNextPage]);

    useEffect(() => {
        if (taskAmount && taskAmount === taskFinished) {
            if (!answered) {
                if (taskColAmount === (taskIndex + 1)) {
                    props.setReadyForNextPage(true);
                }

                setAnswered(true);
                props.increaseAnswered();
            }
        } else if (answered) {
            setAnswered(false);
            props.decreaseAnswered();
        }
    }, [taskFinished, taskAmount, answered, props, taskColAmount, taskIndex]);

    const increaseTasks = useCallback((amount = 1) => {
        setTaskFinished((prev) => prev + amount);
    }, []);

    const decreaseTasks = useCallback((amount = 1) => {
        setTaskFinished((prev) => prev - amount);
    }, []);

    const renderTask = () => {
        const elements = props.element.colElements[taskIndex];

        return (
            elements.map((element) => (
                <GetElement
                    key={element.id}
                    element={element}
                    setValues={props.setValues}
                    increaseAnswered={increaseTasks}
                    decreaseAnswered={decreaseTasks}
                    answerJson={props.answerJson}
                    setReadyForNextPage={props.setReadyForNextPage}
                    pageId={props.pageId}
                />
            ))
        );
    };

    return (
        <div>
            {renderTask()}
        </div>
    );
};

RendererMailTasks.propTypes = {
    element: PropTypes.object.isRequired,
    setValues: PropTypes.func.isRequired,
    increaseAnswered: PropTypes.func.isRequired,
    decreaseAnswered: PropTypes.func.isRequired,
    answerJson: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    setReadyForNextPage: PropTypes.func.isRequired,
    pageId: PropTypes.string.isRequired,
}

export default RendererMailTasks;