import React from "react";

import {Container} from "@mui/material";

function Footer() {
    return (
        <footer className='footer'>
            <Container>
                Brooklynmaxx GmbH, Agrippinawerft 10, D - 50678 Köln, <a className="footerText"
                                                                         href="https://brooklynmaxx.com" target="_blank"
                                                                         rel="noreferrer">www.brooklynmaxx.com</a>
            </Container>
        </footer>
    )
}

export default Footer;