import React, {useState} from 'react';
import PropTypes from "prop-types";
import {TextField, Typography} from "@mui/material";
import GlobalTrans from "../../../helper/GlobalTrans";
import GetTranslation from "../../../helper/GetTranslation";

const RendererTextInput = (props) => {
    const [answered, setAnswered] = useState(false);
    const [text, setText] = useState('');

    const handleChange = (event) => {
        props.onChangeHandler(event.target.value);
        setText(event.target.value);

        if (event.target.value && !answered) {
            setAnswered(true);
            props.answered();
        } else if (!event.target.value) {
            setAnswered(false);
            props.notAnswered();
        }
    };

    return (
        <React.Fragment>
            <Typography sx={{mb: 2}}>
                <GetTranslation
                    translations={props.element?.translations}
                    defaultContent={props.element.content}
                    translationField={'content'}
                />
            </Typography>
            <TextField label={GlobalTrans('text')}
                       autoComplete={'off'}
                       variant="outlined"
                       onChange={handleChange}
                       value={text}
                       multiline
                       minRows={20}
                       spellCheck={false}
                       fullWidth
                       required
            />
        </React.Fragment>
    );
};

RendererTextInput.propTypes = {
    element: PropTypes.object.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    answered: PropTypes.func.isRequired,
    notAnswered: PropTypes.func.isRequired,
}

export default RendererTextInput;