import React, {useEffect, useState} from 'react';
import {Box, Button, Divider, Grid, TextField} from "@mui/material";
import axios from "axios";

import GlobalTrans from "../../helper/GlobalTrans";
import Notifications from "../../components/notifications/Notifications";
import {useStore} from "../../store/useStore";
import Validation from "../../helper/Validation";
import {useIntl} from "react-intl";
import ForgotPassword from "../../components/forgotPassword/ForgotPassword";
import {login} from "../../helper/Helper";
import {LoadingButton} from "@mui/lab";

const Login = () => {
    const {state, dispatch} = useStore();
    const intl = useIntl();

    const [cancelToken] = useState(axios.CancelToken.source());
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(true);
    const [password, setPassword] = useState('');
    const [forgotPassword, setForgotPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    // Notifications
    const [notificationError, setNotificationError] = useState(false);
    const [notificationErrorMessage, setNotificationErrorMessage] = useState('');

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken, state.authenticationError, dispatch]);

    const validateEmail = () => {
        let validationEmail = Validation.isEmail(email);

        setValidEmail(validationEmail);

        return validationEmail;
    }

    const errorCallback = (error) => {
        setNotificationError(error);
        setLoading(false)
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            email,
            password
        };

        if (!validateEmail()) {
            setLoading(false);
            return;
        }

        setNotificationError(false);
        setNotificationErrorMessage('');

        login(data, state, dispatch, intl, cancelToken, errorCallback, setNotificationErrorMessage);
    }

    const onForgotPassword = () => {
        setForgotPassword(true);
    }

    return (
        (
            forgotPassword &&
            <ForgotPassword returnFunction={() => setForgotPassword(false)}/>
        )
        ||
        <React.Fragment>
            <Notifications
                error={notificationError}
                errorMessage={notificationErrorMessage}
                cols={{xs: 12}}
                grid={{mb: 5}}
            />
            <form onSubmit={onSubmit}>
                <Grid container className={'login'} rowSpacing={2}>
                    <Grid item xs={12}>
                        <TextField id="email"
                                   label={GlobalTrans('email')}
                                   variant="outlined"
                                   value={email}
                                   onChange={(e) => {
                                       setEmail(e.target.value)
                                   }}
                                   error={!validEmail}
                                   helperText={GlobalTrans('error_email')}
                                   fullWidth
                                   required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="password"
                                   label={GlobalTrans('password')}
                                   variant="outlined"
                                   type={"password"}
                                   value={password}
                                   onChange={(e) => {
                                       setPassword(e.target.value)
                                   }}
                                   fullWidth
                                   required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton
                            className={'login--submit'}
                            loading={loading}
                            variant={"contained"}
                            type={"submit"}
                            disabled={loading}
                            fullWidth
                        >
                            {GlobalTrans('login')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
            <Box mt={2}>
                <Divider/>
            </Box>
            <Button className={'login--forgot-password'} variant="link" onClick={onForgotPassword}>
                {GlobalTrans('forgot_password')}
            </Button>
        </React.Fragment>
    );
};

export default Login;