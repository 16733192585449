import React, {useCallback, useState} from 'react';
import PropTypes from "prop-types";
import {FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import GetTranslation from "../../../helper/GetTranslation";
import config from "../../../config/config";
import {useStore} from "../../../store/useStore";

const RendererRadio = (props) => {
    const {state} = useStore();

    const [answered, setAnswered] = useState(false);
    const [selected, setSelected] = useState('');

    const handleChange = (event) => {
        props.onChangeHandler(parseInt(event.target.value, 10));
        setSelected(event.target.value);

        if (!answered) {
            setAnswered(true);
            props.answered();
        }
    };

    const getTranslation = useCallback(() => {
        if (props.element?.translations) {
            const translation = props.element.translations.find(translation => translation.language === state.localeIRI);

            if (translation) {
                return translation;
            }
        }

        return null;
    }, [props.element.translations, state.localeIRI]);

    const getOptionTranslation = useCallback((index) => {
        if (!props.element.options[index]) {
            return index;
        }

        if (state.localeIRI !== config.defaultLocale) {
            const translation = getTranslation();

            if (translation && translation.options) {
                return translation.options[index];
            }

            return props.element.options[index];
        } else {
            return props.element.options[index];
        }
    }, [props.element.options, state.localeIRI, getTranslation]);

    return (
        <React.Fragment>
            <Typography mb={2}>
                <GetTranslation
                    translations={props.element?.translations}
                    defaultContent={props.element.label}
                    translationField={'label'}
                />
            </Typography>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id={`dropdownm-select-label-${props.element.id}`}>
                            <GetTranslation
                                translations={props.element?.translations}
                                defaultContent={props.element.label}
                                translationField={'label'}
                            />
                        </InputLabel>
                        <Select
                            labelId={`dropdownm-select-label-${props.element.id}`}
                            id={`dropdownm-${props.element.id}`}
                            value={selected}
                            onChange={handleChange}
                            label={<GetTranslation
                                translations={props.element?.translations}
                                defaultContent={props.element.label}
                                translationField={'label'}
                            />}
                        >
                            {props.element.options.map((option, index) => (
                                <MenuItem value={index} key={index}>{getOptionTranslation(index)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

RendererRadio.propTypes = {
    element: PropTypes.object.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    answered: PropTypes.func.isRequired,
}

export default RendererRadio;