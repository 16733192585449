import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import Validation from "../../helper/Validation";
import axios from "axios";
import Notifications from "../../components/notifications/Notifications";
import {useStore} from "../../store/useStore";
import config from "../../config/config";
import {useIntl} from "react-intl";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import {inputOnlyInteger, login, selectIconComponent} from "../../helper/Helper";

const Register = () => {
    const {state, dispatch} = useStore();
    const intl = useIntl();

    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastname] = useState('');
    const [gender, setGender] = useState('');
    const [age, setAge] = useState('');
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(true);
    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(true);
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [validPasswordRepeat, setValidPasswordRepeat] = useState(true);
    const [genders, setGenders] = useState([]);
    const [loadingGenders, setLoadingGenders] = useState(true);

    // Notifications
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(GlobalTransIntl('notification_error', intl));

    const getGenders = useCallback(() => {
        axios.get(config.apiUrl + `/genders`, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
            .then(res => {
                if (res.data) {
                    setGenders(res.data['hydra:member']);
                    setLoadingGenders(false);
                }
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    setNotificationError(true)
                }
            });
    }, [state.token, cancelToken.token]);

    useEffect(() => {
        if (!mounted) {
            setMounted(true);
            getGenders();
        }
    }, [mounted, getGenders])

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken])

    const validateEmail = () => {
        const isEmailValid = Validation.isEmail(email);

        setValidEmail(isEmailValid);

        return isEmailValid;
    }

    const validatePasswords = () => {
        const isPasswordValid = Validation.validatePassword(password);
        const isPasswordRepeatValid = Validation.validatePasswordRepeat(password, passwordRepeat);

        setValidPassword(isPasswordValid);
        setValidPasswordRepeat(isPasswordRepeatValid);

        return isPasswordValid && isPasswordRepeatValid;
    }

    const validateForm = () => {
        const emailValidation = validateEmail();
        const passwordValidation = validatePasswords();

        return emailValidation && passwordValidation;
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setErrorMessage(GlobalTransIntl('notification_error', intl));

        if (validateForm()) {
            const data = {
                firstName,
                lastName,
                email,
                password,
                age: parseInt(age) || null,
                pin: state.pin
            };

            if (gender) {
                data.gender = gender;
            }

            setNotificationError(false);

            axios.post(config.apiUrl + `/users`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then((res) => {
                    if (res.data && res.data['@id']) {
                        const loginData = {
                            email,
                            password
                        };

                        login(loginData, state, dispatch, intl, cancelToken, setNotificationError, setErrorMessage);
                    } else {
                        setNotificationError(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response?.data?.violations) {
                        if (error.response.data.violations[0].propertyPath === 'email') {
                            setErrorMessage(GlobalTransIntl('error_register_email', intl));
                        }
                    }

                    setNotificationError(true)
                })
        }
    }

    return (
        loadingGenders ? (
            <Grid container justifyContent={"center"}>
                <Grid item>
                    <CircularProgress/>
                </Grid>
            </Grid>
        ) : (
            <form onSubmit={onSubmit}>
                <Notifications
                    error={notificationError}
                    errorMessage={errorMessage}
                    cols={{xs: 12}}
                    grid={{mb: 5}}
                />
                <Grid container className={'register'} rowSpacing={2}>
                    <Grid item xs={12}>
                        <TextField id="first_name"
                                   label={GlobalTransIntl('first_name', intl)}
                                   variant="outlined"
                                   value={firstName}
                                   onChange={(e) => {
                                       setFirstName(e.target.value)
                                   }}
                                   fullWidth
                                   required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="last_name"
                                   label={GlobalTransIntl('last_name', intl)}
                                   variant="outlined"
                                   value={lastName}
                                   onChange={(e) => {
                                       setLastname(e.target.value)
                                   }}
                                   fullWidth
                                   required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant={'outlined'} id={'gender'} fullWidth>
                            <InputLabel>
                                {GlobalTransIntl('gender', intl)}
                            </InputLabel>
                            <Select
                                id="gender_select"
                                value={gender}
                                onChange={(event) => {
                                    setGender(event.target.value)
                                }}
                                label={GlobalTransIntl('gender', intl)}
                                IconComponent={selectIconComponent}
                            >
                                {
                                    genders.map((item) =>
                                        <MenuItem value={item['@id']} key={item.id}
                                                  data-name={item.name}>{item.name}</MenuItem>
                                    )
                                }
                            </Select>
                            <Box fontWeight={'bold'} px={2}>{GlobalTransIntl('optional_field', intl)}</Box>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="age"
                                   label={GlobalTransIntl('age', intl)}
                                   variant="outlined"
                                   value={age}
                                   onChange={(e) => inputOnlyInteger(e, setAge)}
                                   fullWidth
                        />
                        <Box fontWeight={'bold'} px={2}>{GlobalTransIntl('optional_field', intl)}</Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="email"
                                   label={GlobalTransIntl('email', intl)}
                                   variant="outlined"
                                   value={email}
                                   onChange={(e) => {
                                       setEmail(e.target.value)
                                   }}
                                   error={!validEmail}
                                   helperText={GlobalTransIntl('error_email', intl)}
                                   fullWidth
                                   required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="password"
                                   label={GlobalTransIntl('password', intl)}
                                   variant="outlined"
                                   type={"password"}
                                   value={password}
                                   onChange={(e) => {
                                       setPassword(e.target.value)
                                   }}
                                   error={!validPassword}
                                   helperText={GlobalTransIntl('error_password', intl)}
                                   fullWidth
                                   required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="password_repeat"
                                   label={GlobalTransIntl('password_repeat', intl)}
                                   variant="outlined"
                                   type={"password"}
                                   value={passwordRepeat}
                                   onChange={(e) => {
                                       setPasswordRepeat(e.target.value)
                                   }}
                                   error={!validPasswordRepeat}
                                   helperText={GlobalTransIntl('error_password_repeat', intl)}
                                   fullWidth
                                   required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant={"contained"} type={"submit"} fullWidth>
                            {GlobalTransIntl('register', intl)}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        )
    );
};

export default Register;