import React, {useCallback} from 'react';
import Content from "../../components/renderer/Content";
import {Paper} from "@mui/material";
import Finished from "../../components/renderer/Finished";
import {useStore} from "../../store/useStore";
import {isPinFinished} from "../../helper/Helper";

const Renderer = () => {
    const {state} = useStore();

    const GetContent = useCallback(() => {
        if (isPinFinished(state.pinFinished, state.pinRessource)) {
            return <Finished/>;
        }

        return <Content/>;
    }, [state.pinFinished, state.pinRessource]);

    return (
        <Paper className={'color-theme main-paper'} sx={{mt: 2}}>
            <GetContent/>
        </Paper>
    );
};

export default Renderer;