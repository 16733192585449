import {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {useStore} from "../store/useStore";

const GetTranslation = (props) => {
    const {state} = useStore();

    const [content, setContent] = useState(props.defaultContent || '');

    useEffect(() => {
        if (props?.translations?.length) {
            const translation = (typeof props.translations[0].language === 'object') ?
                props.translations.find(translation => translation.language['@id'] === state.localeIRI) :
                props.translations.find(translation => translation.language === state.localeIRI);

            if (translation) {
                setContent(translation[props.translationField || 'content']);
            }
        }
    }, [props.translations, state.localeIRI, props.translationField]);

    return content;
};

GetTranslation.propTypes = {
    translations: PropTypes.array,
    defaultContent: PropTypes.string,
    translationField: PropTypes.string,
}

export default GetTranslation;