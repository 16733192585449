import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {useDrop} from "react-dnd";
import SortItemElement from "../../../helper/SortItemElement";
import {Box, Grid} from "@mui/material";
import {v4 as uuidv4} from "uuid";
import {useStore} from "../../../store/useStore";
import config from "../../../config/config";

const RendererForcedChoice = (props) => {
    const {state} = useStore();
    const [mounted, setMounted] = useState(false);

    const [items, setItems] = useState(props.element.options);
    const [dropItemName] = useState('forcedChoiceDropItem_' + uuidv4());
    const [translations, setTranslations] = useState(props.element.translations || []);

    const formatAnswer = useCallback((items) => {
        const formattedAnswer = [];

        items.forEach((item) => {
            formattedAnswer.push(item.id);
        })

        return formattedAnswer;
    }, []);

    const getTranslation = useCallback(() => {
        if (translations) {
            const translation = translations.find(translation => translation.language === state.localeIRI);

            if (translation) {
                return translation;
            }
        }
    }, [translations, state.localeIRI]);

    const getItemLabelTranslation = useCallback((index, defaultLabel) => {
        if (state.localeIRI !== config.defaultLocale) {
            const translation = getTranslation();

            if (translation) {
                return translation.options[index];
            }

            return defaultLabel;
        } else {
            return defaultLabel;
        }
    }, [getTranslation, state.localeIRI]);

    const getOrderLabelTranslation = useCallback((index) => {
        if (!props.element.orderLabels[index]) {
            return index;
        }

        if (state.localeIRI !== config.defaultLocale) {
            const translation = getTranslation();

            if (translation && translation.orderLabels) {
                return translation.orderLabels[index];
            }

            return props.element.orderLabels[index];
        } else {
            return props.element.orderLabels[index];
        }
    }, [getTranslation, props.element.orderLabels, state.localeIRI]);

    useEffect(() => {
        if (!mounted) {
            setMounted(true);

            const sortedItems = [...items];
            sortedItems.sort(() => Math.random() - 0.5);

            setItems(sortedItems);
            props.onChangeHandler(formatAnswer(sortedItems));
        }
    }, [mounted, items, props, formatAnswer]);

    const findItem = useCallback(
        (id) => {
            const item = items.filter((c) => `${c.id}` === id)[0];

            return {
                item,
                index: items.indexOf(item),
            }
        },
        [items],
    );

    const moveTranslationItem = useCallback((index, atIndex) => {
        if (translations.length) {
            const newTranslations = [...translations];

            newTranslations.forEach((translation) => {
                const newItem = translation.options[index];

                translation.options.splice(index, 1);
                translation.options.splice(atIndex, 0, newItem);
            });

            setTranslations(newTranslations);
        }
    }, [translations]);

    const moveItem = useCallback(
        (id, atIndex) => {
            const {item, index} = findItem(id);
            const newItems = [...items];

            newItems.splice(index, 1);
            newItems.splice(atIndex, 0, item);

            moveTranslationItem(index, atIndex);

            setItems(newItems);
            props.onChangeHandler(formatAnswer(newItems));
        },
        [findItem, items, setItems, props, formatAnswer, moveTranslationItem],
    );

    const [, drop] = useDrop(() => ({accept: dropItemName}));

    return (
        <Box className={'forced-choice'}>
            <Grid container ref={drop} spacing={2} alignItems={'center'}
                  sx={{border: '1px solid gray', pt: 1, pr: 2, pb: 3}}>
                {items.map((item, index) => (
                    <React.Fragment key={item.id}>
                        <Grid item xs={2}>
                            {getOrderLabelTranslation(index)}
                        </Grid>
                        <Grid item xs={10}>
                            <SortItemElement
                                id={`${item.id}`}
                                text={getItemLabelTranslation(index, item.label)}
                                moveItem={moveItem}
                                findItem={findItem}
                                dragType={dropItemName}
                            />
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </Box>
    );
};

RendererForcedChoice.propTypes = {
    element: PropTypes.object.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    answered: PropTypes.func.isRequired,
}

export default RendererForcedChoice;