import React, {useCallback, useEffect, useState} from 'react';
import {useStore} from "../../store/useStore";
import SetPin from "../../pages/setPin/SetPin";
import Authentication from "../../pages/authentication/Authentication";
import {getUrlParam, isPinFinished} from "../../helper/Helper";
import Frontpage from "../../pages/frontpage/Frontpage";
import axios from "axios";
import config from "../../config/config";
import TokenRefresher from "../../helper/TokenRefresher";
import Renderer from "../../pages/renderer/Renderer";
import Notifications from "../notifications/Notifications";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import {useIntl} from "react-intl";
import {Grid, Paper} from "@mui/material";
import Ocs from "../../pages/ocs/Ocs";

const Content = () => {
    const intl = useIntl();
    const {state, dispatch} = useStore();

    const [cancelToken] = useState(axios.CancelToken.source());
    const [error, setError] = React.useState('');

    const getOwnership = useCallback(() => {
        axios.get(config.apiUrl + `/pin/${state.locale}/getOwnership/${state.pinId}`, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
            .then(() => {
                dispatch({
                    type: 'setPinHasOwner',
                    payload: true
                });
            })
            .catch(() => {
                setError(GlobalTransIntl('error_get_ownership', intl));
                console.log("error");
            });
    }, [cancelToken.token, dispatch, state.locale, state.pinId, state.token, intl]);

    const resetTokens = useCallback(() => {
        dispatch({
            type: 'deleteTokens'
        });
    }, [dispatch]);

    const getPinFromApi = useCallback(() => {
        // Get pin
        axios.get(config.apiUrl + `/pins/${state.pinId}`, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
            .then(res => {
                if (res.data) {
                    if (res.data.status.id !== 1 && res.data.status.id !== 2) {
                        dispatch({
                            type: 'startPin'
                        });
                    }

                    dispatch({
                        type: 'setPinRessource',
                        payload: res.data
                    });

                    if (res.data.isForcedLanguage) {
                        if (res.data.language?.code) {
                            dispatch({type: "setLocale", payload: res.data.language.code});
                        } else {
                            dispatch({type: "setLocale", payload: 'en'});
                        }
                    }
                } else {
                    resetTokens();
                }
            })
            .catch(() => {
                setError(GlobalTransIntl('error_get_pin', intl));
                resetTokens();
            });
    }, [cancelToken.token, dispatch, state.pinId, state.token, resetTokens, intl]);

    useEffect(() => {
        if (state.token && state.pin && !state.pinFinished) {
            if (!state.pinHasOwner) {
                getOwnership();
            } else if (Object.keys(state.pinRessource).length === 0) {
                getPinFromApi();
            }
        }
    }, [getOwnership, getPinFromApi, state.pinRessource, state.token, state.pinFinished, state.pinHasOwner, state.pin])

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken]);

    const GetContent = useCallback(() => {
        if ((state.pinStarted && (state.pinRessource.status.id > 1)) || state.pinFinished) {
            const moduleJson = state.pinRessource.modulesJson;
            const currentModule = state.moduleIndex;

            if (moduleJson && moduleJson[currentModule]?.ocs && !isPinFinished(state.pinFinished, state.pinRessource)) {
                return <Ocs/>;
            }

            return <Renderer/>;
        }

        return <Frontpage/>
    }, [state.pinStarted, state.pinFinished, state.moduleIndex, state.pinRessource]);

    const getContentComponent = () => {
        if (error) {
            return (
                <Grid container justifyContent={'center'}>
                    <Grid item xs={12} md={6}>
                        <Paper className={'color-theme main-paper'}>
                            <Notifications
                                error={true}
                                errorMessage={error}
                                cols={{xs: 12}}
                                grid={{mb: 2}}
                            />
                            <Notifications
                                info={true}
                                infoMessage={GlobalTransIntl('error_renderer', intl)}
                                cols={{xs: 12}}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            )
        }

        if (getUrlParam('pin') || (!state.pin && !state.pinFinished)) {
            return <SetPin/>;
        }

        if (!state.token) {
            return <Authentication/>;
        }

        if (Object.keys(state.pinRessource).length === 0 && !state.pinFinished) {
            return null;
        }

        return (
            <React.Fragment>
                <TokenRefresher/>
                <GetContent/>
            </React.Fragment>);
    }

    return getContentComponent();
};

export default Content;