const Validation = {
    isEmail: function (email) {
        let regEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
        return regEmail.test(email);
    },
    validatePassword: function (password) {
        return password.length >= 8
    },
    validatePasswordRepeat(password1, password2) {
        return password1 === password2;
    },
    isNotEmpty: function (value) {
        return (value.length > 0);
    }
}

export default Validation;